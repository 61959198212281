<script setup>

const loading = ref(false)
const search = ref('')
const selectedPost = ref({})
const { Settings } = useSettings()

const data = reactive({
  selectedPost: {}
})

const editPostDialog = ref()

const fetchApi = `/api/post` + ((Settings.value.showBlobInHome && Settings.value.blobIdInHome) ? `/${Settings.value.blobIdInHome}` : '')
const { data: blob } = (Settings.value.showBlobInHome && Settings.value.blobIdInHome) ? await useFetch(`/api/blob/${Settings.value.blobIdInHome}`, { key: 'aa' + Settings.value.blobIdInHome }) : ({ data: null  })
const { data: posts = [], pending, refresh, error } = await useFetch(fetchApi)

const preloadImage = computed( () => posts?.value?.length && posts?.value[0]?.image ?
  '/media/' + posts?.value[0]?.image.replace(/.jpg$/, '.webp') : 
  '/media/fallbackImage.webp')

useHead( {
  title: Settings?.value?.name || 'Blob',
  link: [
    { rel: 'alternate', type: 'application/rss+xml', title: Settings?.value?.name || 'Blob', href: '/feed' },
    { rel: 'preload', as: 'image', href: preloadImage },
    { rel: 'preload', as: 'image', href: '/media/logo' }
  ]
})


const infiniteScrolling = async (isIntersecting, entries, observer) => {
  if (isIntersecting && posts?.value?.length) {
    loading.value = true

    if (Settings.value.showBlobInHome && Settings.value.blobIdInHome) {
      const sortBy = blob.value.sortBy
      const timestamp = new Date(posts.value[posts.value.length-1].date).getTime()
      const params = new URLSearchParams({ after: sortBy === 'date' ? timestamp : posts.value[posts.value.length-1][sortBy] })
      const ret = await $fetch(`${fetchApi}?${params}`)
      if (ret?.length) {
        posts?.value.push(...ret)
      }  
      
    } else {
      const ret = await $fetch(`${fetchApi}?after=${posts?.value[posts?.value?.length-1].date}`)
      if (ret?.length) {
        posts?.value.push(...ret)
      }
    }
    loading.value = false
  }
}

function editPost(post) {
  editPostDialog.value.open(post)
}

</script>

<template>
  <div>
    <EditPost ref="editPostDialog" :post="selectedPost" @update="refresh"/>
    <Blobs id="blob_blobs"/>
    <p v-if='Settings.tagline' v-html='Settings.tagline' id="blob_tagline"/>
    <section class='container max-w-80 mt-6 postContainer'>
      <Post v-for='(post, id) in posts'
        card
        :key='post.id'
        :post='post'
        @edit="editPost"
        @remove="posts.splice(id, 1)"
        @toggleVisibility="post.visible = !post.visible"/>
    </section>
    <div class='pa-1 ma-12 text-center' v-intersect="infiniteScrolling">
      <v-progress-circular v-if='loading' indeterminate />
    </div>
  </div>
</template>
<style>
.theme-Grid .postContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 15px 15px;
}

.theme-Blob .postContainer .post {
  margin-bottom: 15px;
}

.theme-Blob .postContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
</style>